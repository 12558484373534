export function ErrorPage({h1, showBuffer=true, extra=null}) {

  const handlePurchasesButton = (event) => {
    event.preventDefault()
    extra(true);
  }

  return (
    <div className="main section" id="main_content" name="Main Content">
      <div
        className="widget Blog"
        data-version="2"
        id="Blog1"
        style={{display: "block"}}
      >
        <div className="row justify-content-center"></div>
        <div className="row justify-content-center">
          <div style={{paddingLeft: "5px", paddingRight: "5px"}} className="col-lg-12">
            <div className="errorWrap card radius-10 shadow-lg p-5 mt-5 text-center">
              <h1 className="display-4">
                {showBuffer && `Oooooh!!!` } <span className="text-primary">{h1}</span>
              </h1>
              <h3 className="h2 mt-3 mb-4">
                Sorry! The page you were looking for, could not be found...
              </h3>
              <div className="flex justify-center space-x-4">  {/* Added flexbox */}
              {extra && (
                  <a onClick={(event) => {handlePurchasesButton(event)} } href="#">
                    <span className="btn btn-outline-dark radius-25 px-4">
                      Buy This Article
                    </span>
                  </a>
                )}
                <a href="/">
                  <span className="btn btn-outline-dark radius-25 px-4">
                    Go To Homepage
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-posts hfeed"></div>
      </div>
    </div>
  );
}



export function EmptyPage({txt}) {

  const handlePurchasesButton = (event) => {
    event.preventDefault()
    extra(true);
  }

  return (
    <div style={{marginBottom: '10px'}} className="main section" id="main_content" name="Main Content">
      <div
        className="widget Blog"
        data-version="2"
        id="Blog1"
        style={{display: "block"}}
      >
        <div className="row justify-content-center"></div>
        <div className="row justify-content-center">
          <div style={{paddingLeft: "5px", paddingRight: "5px"}} className="col-lg-12">
            <div className="errorWrap card radius-10 shadow-lg p-5 mt-5 text-center">
              <h1 className="display-4">
                {`Oooooh!!!`}
              </h1>
              <h3 className="h2 mt-3 mb-4">
                {txt}
              </h3>
              <div className="flex justify-center space-x-4">
                <a href="/">
                  <span className="btn btn-outline-dark radius-25 px-4">
                    Go To Homepage
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-posts hfeed"></div>
      </div>
    </div>
  );
}

export function ExpiredSubscription({h1, showBuffer=true, extra=null}) {


  return (
    <div className="main section" id="main_content" name="Main Content">
      <div
        className="widget Blog"
        data-version="2"
        id="Blog1"
        style={{display: "block"}}
      >
        <div className="row justify-content-center"></div>
        <div className="row justify-content-center">
          <div style={{paddingLeft: "5px", paddingRight: "5px"}} className="col-lg-12">
            <div className="errorWrap card radius-10 shadow-lg p-5 mt-5 text-center">
              <h1 className="display-4">
                {showBuffer && `Oooooh!!!` } <span className="text-primary">{h1}</span>
              </h1>
              <div className="flex justify-center space-x-4">  {/* Added flexbox */}
                <a href="/pricing/">
                  <span className="btn btn-outline-dark radius-25 px-4">
                    Subscribe
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-posts hfeed"></div>
      </div>
    </div>
  );
}