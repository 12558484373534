import React from 'react';
import { Route, Navigate, useNavigate } from 'react-router-dom';

export function PrivateRoute({ element, ...rest }) {
  const token = localStorage.getItem('token'); // Replace with your auth logic
  const navigate = useNavigate()
  if(!token){
    navigate('/account/')
  }

  return (
    <>{element}</>
  );
}


