import { FeederChart } from "@/routes/dashboard/feed-chart";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Newspaper, EyeIcon } from "lucide-react";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "@/config";
import { useNavigate } from "react-router-dom";

import { Badge } from "@/components/ui/badge";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";

export function PaginateTransaction() {
  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious href="#" />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink href="#">1</PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink href="#" isActive>
            2
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink href="#">3</PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationEllipsis />
        </PaginationItem>
        <PaginationItem>
          <PaginationNext href="#" />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
}

const TransactionList = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage]);

  const fetchTransactions = async (page) => {
    try {
      let token = localStorage.getItem("token");
      const response = await axios.get(
        `${API_URL}/api/v1/payments/purchases/transactions/?page=${page}&page_size=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTransactions(response.data.results);
      setTotalPages(Math.ceil(response.data.count / pageSize));
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  console.log(transactions);

  return (
    <div className="transaction-list">
      <Card>
        <CardHeader>
          <CardTitle>Transaction History</CardTitle>
        </CardHeader>
        <CardContent>
          {transactions && transactions.length > 0 ? (
            <div className="transaction-list mx-auto px-4 sm:px-6 lg:px-8">
              <div className="space-y-4">
                {transactions.length > 0 ? (
                  <>
                    <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                      {transactions.map((transaction) => (
                        <div
                          key={transaction.id}
                          className="bg-white p-4 rounded-lg shadow-md"
                        >
                          <div className="mb-2">
                            <span className="font-semibold">Amount:</span> $
                            {transaction.amount}
                          </div>
                          <div className="mb-2">
                            <span className="font-semibold">Status:</span>
                            <Badge
                              className={
                                transaction.status === "completed"
                                  ? "bg-info"
                                  : "bg-danger"
                              }
                            >
                              {transaction.status}
                            </Badge>
                          </div>
                          <div className="mb-2">
                            <span className="font-semibold">Date:</span>{" "}
                            {new Date(
                              transaction.created_at
                            ).toLocaleDateString()}
                          </div>
                        </div>
                      ))}
                    </div>
                    <PaginateTransaction
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onChange={handlePageChange}
                    />
                  </>
                ) : (
                  <p className="text-gray-500">No transactions found.</p>
                )}
              </div>
            </div>
          ) : (
            <p>No transactions found.</p>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

const BankList = () => {
  const [bank, setBank] = useState([]);
  const [amount, setAmount] = useState(0);
  const [prevous, setPrevious] = useState(false);
  const [next, setNext] = useState(false);
  const [page, setPage] = useState(1);
  const token = localStorage.getItem("token");

  const fetchBank = async () => {
    try {
      const resp = await axios.get(
        `${API_URL}/api/v1/news/banks/?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNext(resp.data.next);
      setPrevious(resp.data.previous);
      setBank(resp.data.results);
    } catch (error) {
    } finally {
    }
  };
  const fetchTotalAmount = async () => {
    try {
      const resp = await axios.get(
        `${API_URL}/api/v1/news/banks/total-amount/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAmount(resp.data.total_amount);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    fetchBank();
    fetchTotalAmount();
  }, []);

  useEffect(() => {
    fetchBank();
  }, [page]);

  return (
    <div>
      <h2 className="text-lg font-bold mb-4">Bank Statement</h2>

      <div className="mt-4">
        <p className="text-sm font-semibold">
          Current Total: <span>{amount || 0}</span>
        </p>
        <p className="text-sm text-gray-500">
          Your balance will be charged when it reaches somewhere between{" "}
          <span className="font-semibold">$8 and $12</span>. For an explanation
          of how the exact value is calculated click{" "}
          <a className="text-blue underline" href="/article/purchases-policy/">
            here
          </a>
          .
        </p>
      </div>

      <ul className="list-disc list-inside space-y-6 mt-6">
        {bank &&
          bank.map((item, index) => (
            <li key={index} className="">
              <a
                href={`/posts/${item.slug}/`}
                className="text-lg font-semibold underline hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.title}
              </a>
              <div className="text-sm text-gray-700 mt-1">
                <a
                  href={`/provider/${item.feeder.slug}/`}
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.feeder.display_name || item.feeder.name}
                </a>
                <span className="text-gray-500">
                  {" "}
                  - Purchased on{" "}
                  {new Date(item.purchases.time).toLocaleString()}
                </span>
              </div>
            </li>
          ))}
      </ul>
      <div className="flex justify-between mt-4">
        <button
          disabled={prevous == null}
          onClick={() => {
            setPage(page - 1);
          }}
          className="bg-info px-4 py-2 bg-gray-300 text-gray-800 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={() => {
            setPage(page + 1);
          }}
          disabled={next == null}
          className="bg-info px-4 py-2 bg-gray-300 text-gray-800 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export function Transaction() {
  return (
    <>
      <div>
        <h3 className="text-lg font-medium">Transaction History</h3>
        <p className="text-sm text-muted-foreground">
          List of article purchases transactions
        </p>
      </div>
      <Separator />

      <BankList />
    </>
  );
}
