import { Footer } from "./footer";
import { SiteHeader } from "./site-header";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function decodeBase64(str) {
  try {
    return atob(str);
  } catch (e) {
    console.error("Invalid base64 string", e);
    return null;
  }
}

export function TenDollarPolicyPage({ article_price = 0.19 }) {
  const location = useLocation();
  const [decodedSlug, setDecodedSlug] = useState(null);

  useEffect(() => {
    // Extract 'ref' from the query params
    const params = new URLSearchParams(location.search);
    const ref = params.get("ref");

    if (ref) {
      // Decode the Base64 reference to get the article slug
      const decoded = decodeBase64(ref);
      setDecodedSlug(decoded);
    }
  }, [location.search]);
  return (
    <>
      {" "}
      <SiteHeader></SiteHeader>
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="max-w-xl w-full bg-white p-8 rounded-lg shadow-md">
          <h1 className="text-3xl font-bold text-gray-800 mb-6">
            $10 Spending Policy
          </h1>
          <p className="text-gray-700 mb-4">
            Our platform allows you to purchase individual articles starting
            from as little as <b>${article_price}</b>, though prices may vary
            depending on the content.
          </p>
          <p className="text-gray-700 mb-4">
            Once your total spending reaches <b>$10</b>, a transaction will be
            initiated to process all your purchases. You will not be billed for
            each individual purchase under $10.
          </p>
          <p className="text-gray-700 mb-4">
            This allows you to make multiple smaller purchases without being
            charged until your total reaches $10. After reaching this amount,
            the transaction is processed, and you will be able to access all
            your purchased content instantly.
          </p>
          <p className="text-gray-700 mb-6">
            Feel free to continue purchasing articles, and we will notify you
            once you've hit the $10 spending limit.
          </p>

          {decodedSlug && (
            <p>
              You were reading an article. Go back to:{" "}
              <a
                href={`/posts/${decodedSlug}`}
                className="text-blue-600 underline"
              >
                Continue to the article
              </a>
            </p>
          )}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
