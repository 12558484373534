import { API_URL, NO_IMAGE_URL } from "@/config";
import { useNavigate } from "react-router-dom";

export const ArticleCard = ({ article }) => {
  // Truncate the excerpt to a maximum of 100 characters
  const truncatedExcerpt = article.excerpt
    ? article.excerpt.length > 100
      ? article.excerpt.substring(0, 100) + "..."
      : article.excerpt
    : "";

  const navigate = useNavigate();

  return (
    <div className="flex flex-col md:flex-row bg-white p-3 shadow-md rounded-lg transform transition-all duration-300 hover:shadow-lg hover:scale-105 relative hover:border-transparent border-gray-200 border-r-4 border-b-4 w-full">
      {/* Clickable container for the card */}
      <div
        className="w-full md:w-1/3 overflow-hidden rounded-lg mb-4 md:mb-0 cursor-pointer"
        onClick={() => navigate(`/posts/${article.slug}/`)}
      >
        {/* Image Section */}
        <img
          className="h-48 w-full object-cover transform transition-transform duration-400 hover:scale-110 rounded-lg"
          src={article.resized_featured_image ? article.resized_featured_image : article.alt_img ? `${API_URL}/${article.alt_img}` : NO_IMAGE_URL}
          alt={article.title}
        />
      </div>

      {/* Content Section */}
      <div className="w-full md:w-2/3 p-3 flex flex-col justify-between">
        {/* Title */}
        <h3 className="text-xl font-semibold text-gray-800 mb-2 underline-transition">
          <a
            href={`/posts/${article.slug}/`}
            className=" text-gray-800 hover:text-gray-800 no-underline relative inline-block pb-1"
          >
            {article.title}
          </a>
        </h3>

        {/* Excerpt */}
        {article.excerpt && (
          <p className="text-gray-600 mt-2 line-clamp-2">{truncatedExcerpt}</p>
        )}

        {/* Provider Info, Date, and Tags */}
        <div className="mt-4">
          {/* Provider and Date */}
          <div className="flex items-center text-sm text-gray-500">
            <span className="font-semibold pointer">
              {article.feeder.display_name || article.feeder.name}
            </span>
            <span className="mx-2">|</span>
            <span>{article.pub_date}</span>
          </div>

          {/* Tags */}
          <div className="flex flex-wrap gap-2 mt-2 max-w-full">
            {article.tags.slice(0, 3).map((tag, index) => (
              <span
                key={index}
                className="pointer bg-blue-200 text-blue-800 px-2 py-1 rounded-full text-xs break-words max-w-full truncate"
              >
                {tag.title}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ArticleCardSkeleton = () => {
  return (
    <div className="flex flex-col md:flex-row bg-gray-100 p-3 shadow-md rounded-lg animate-pulse w-full py-5">
      {/* Image Section Skeleton */}
      <div className="w-full md:w-1/3 overflow-hidden rounded-lg mb-4 md:mb-0 bg-gray-300 h-48"></div>

      {/* Content Section Skeleton */}
      <div className="w-full md:w-2/3 p-3 flex flex-col justify-between space-y-4">
        {/* Title Skeleton */}
        <div className="h-6 bg-gray-300 rounded w-3/4"></div>

        {/* Excerpt Skeleton */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-300 rounded w-full"></div>
          <div className="h-4 bg-gray-300 rounded w-5/6"></div>
        </div>

        {/* Provider Info and Date Skeleton */}
        <div className="flex items-center space-x-2">
          <div className="h-4 bg-gray-300 rounded w-1/4"></div>
          <span className="h-4 bg-gray-300 rounded w-1"></span>
          <div className="h-4 bg-gray-300 rounded w-1/6"></div>
        </div>

        {/* Tags Skeleton */}
        <div className="flex space-x-2 mt-2">
          {[1, 2, 3].map((_, index) => (
            <div
              key={index}
              className="bg-gray-300 rounded-full h-6 w-16"
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};
