import * as React from "react";

import { Index } from "@/routes/index";
import { SignIn } from "@/routes/auth/login";
import { VerifyPage } from "@/routes/auth/verify";
import { AuthenticationPage } from "@/routes/auth/signup";
import { Dashbord } from "@/routes/dashboard/index";
import { SinglePost } from "@/routes/posts";
import { ProviderPost } from "@/routes/providers";
import { PriceTable } from "@/routes/pricing-tables";
import { SelectFeeds } from "@/routes/feeds";
import { FAQPage } from "@/routes/faq";
import { TagsPost } from "@/routes/tags";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Today } from "./routes/today";
import { TenDollarPolicyPage } from "./routes/purchases-policy";
import { PrivateRoute } from "./private";
import { BookMarkPost } from "./routes/bookmark";
import { Navbar } from "./routes/navbar";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/login/" element={<SignIn />} />
        <Route path="/account/" element={<AuthenticationPage />} />
        <Route path="/today/" element={<Today />} />
        <Route path="/tags/:slug/" element={<TagsPost />} />
        <Route path="/posts/:slug/" element={<SinglePost />} />
        <Route path="/provider/:slug/" element={<ProviderPost />} />

        <Route path="/dashboard/*" element={<PrivateRoute element={<Dashbord />} />} />
        <Route path="/email/verify/" element={<VerifyPage />} />

        <Route path="/pricing/" element={<PriceTable />} />
        <Route path="/feeds/" element={<SelectFeeds />} />
        <Route path="/faqs/" element={<FAQPage />} />
        <Route path="/book-mark/" element={<BookMarkPost />} />
        <Route path="/test/" element={<Navbar />} />
        <Route
          path="/article/purchases-policy/"
          element={<TenDollarPolicyPage />}
        />
      </Routes>
    </Router>
  );
}

export default App;
