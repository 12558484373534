import { FeederChart } from "@/routes/dashboard/feed-chart";
import { CalendarDateRangePicker } from "@/routes/dashboard/date-range-picker";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Newspaper, EyeIcon } from "lucide-react";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "@/config";
import { useNavigate } from "react-router-dom";

export function ListOfActivity() {
  const [loading, setLoading] = useState(false);
  const [feeds, setFeeds] = useState([]);
  const [read, setFeedViewData] = useState(null)
  const navigate = useNavigate()
  const [initialized, setInitialized] = useState(false);

  // Function to fetch data
  const fetchSubscribedFeeds = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let header = {};
    if (token) {
      header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    try {
      const subs = await axios.get(
        `${API_URL}/api/v1/users/feeds/subscribed/`,
        header
      )

     setFeeds(subs.data)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        navigate("/account/");
      }
      console.error("Error fetching data:", error);
    }
    finally{
      setLoading(false);
    }
  };

  const fetchReadData = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let header = {};
    if (token) {
      header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    try {
      const subs = await axios.get(
        `${API_URL}/api/v1/users/feeds/read/`,
        header
      )

     setFeedViewData(subs.data)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        navigate("/account/");
      }
      console.error("Error fetching data:", error);
    }
    finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      fetchSubscribedFeeds();
      fetchReadData();
      setInitialized(true);
    }
  }, [initialized]);

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Total Feed</CardTitle>
          <Newspaper className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">+{feeds && feeds.length}</div>
          <p className="text-xs text-muted-foreground">
            Number of custom feeds in your list.
          </p>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Article</CardTitle>
          <EyeIcon className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">*{read && read.total_article || 0}</div>
          <p className="text-xs text-muted-foreground">{read && read.total_minutes || 0} minutes total read time.</p>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Purchases</CardTitle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="h-4 w-4 text-muted-foreground"
          >
            <rect width="20" height="14" x="2" y="5" rx="2" />
            <path d="M2 10h20" />
          </svg>
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">+{read && read.total_purchase || 0}</div>
          <p className="text-xs text-muted-foreground">Number of article you have purchased</p>
        </CardContent>
      </Card>
      {/* <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Active Now</CardTitle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="h-4 w-4 text-muted-foreground"
          >
            <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
          </svg>
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">+573</div>
          <p className="text-xs text-muted-foreground">+201 since last hour</p>
        </CardContent>
      </Card> */}
    </div>
  );
}

export function ActivityComp() {
  return (
    <>
      <div>
        <h3 className="text-lg font-medium">Activity</h3>
        <p className="text-sm text-muted-foreground">
          This month of activity in Scrollnews.
        </p>
      </div>
      <Separator />
      {/* <div className="flex items-center justify-between space-y-2">
        <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>
        <div className="flex items-center space-x-2">
          <CalendarDateRangePicker />
          <Button className="bg-info">Show</Button>
        </div>
      </div> */}

      <ListOfActivity />
      <FeederChart />
    </>
  );
}
