import * as React from "react";
import { useState, useEffect } from "react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { CommandDialog, CommandList } from "@/components/ui/command";
import { Search } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import axios from "axios";

import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useNavigate } from "react-router-dom";
import { API_URL } from "@/config";
// import {UserNav} from "@/routes/user-nav"

export function SearchList({ modal, searchValue }) {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!searchValue) {
        setPosts([]);
        return;
      }
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/news/?search=${searchValue}`
        );
        setPosts(response.data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchValue]); // Include searchValue in the dependency array

  return (
    <ScrollArea className="h-screen">
      <div className="flex flex-col gap-2 p-4 pt-0">
        {posts.length == 0 && (
          <p style={{ "text-align": "center" }}>No result found!</p>
        )}
        {posts.map((item) => (
          <a
            key={item.alias}
            className={cn(
              "flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent"
            )}
            href={`/posts/${item.slug}/`}
            // onClick={() => {
            //   navigate(`/posts/${item.slug}/`);
            //   modal(false);
            // }}
          >
            <div className="flex w-full flex-col gap-1">
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <div className="font-semibold">{item.title}</div>
                  {/* <span class="flex h-2 w-2 rounded-full bg-green-600"></span> */}
                </div>
              </div>
              <div className="text-xs font-medium">
                {item.feeder.display_name || item.feeder.name} / {item.pub_date}
              </div>
            </div>
            <div className="line-clamp-2 text-xs text-muted-foreground">
              {item.excerpt && item.excerpt.substring(0, 300)}
              {!item.excerpt && item.content.substring(0, 300)}
            </div>
            {/* <div className="flex items-center gap-2">
              {item.tags &&
                item.tags.map((label, index) => (
                  <Badge
                    className="bg-info"
                    key={label.alias}
                    variant="default"
                  >
                    {label.title}
                  </Badge>
                ))}
            </div> */}
          </a>
        ))}
      </div>
    </ScrollArea>
  );
}

export function SearchMenu({ ...props }) {
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [debouncedValue, setDebouncedValue] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchValue.length >= 2) {
        setDebouncedValue(searchValue);
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    // if (debouncedValue) {
    //   handleSearch();
    // }
  }, [debouncedValue]);

  React.useEffect(() => {
    const down = (e) => {
      if ((e.key === "k" && (e.metaKey || e.ctrlKey)) || e.key === "/") {
        if (
          (e.target instanceof HTMLElement && e.target.isContentEditable) ||
          e.target instanceof HTMLInputElement ||
          e.target instanceof HTMLTextAreaElement ||
          e.target instanceof HTMLSelectElement
        ) {
          return;
        }

        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  const runCommand = React.useCallback(() => {
    console.log("Hello Wrols");
    setOpen(false);
    // command()
    console.log("Helllo Worls.");
  }, []);

  return (
    <>
      <Button
        variant="outline"
        className={cn(
          "relative h-8 w-full justify-start rounded-[0.5rem] bg-background text-sm font-normal text-muted-foreground shadow-none sm:pr-12 md:w-64 lg:w-64"
        )}
        onClick={() => setOpen(true)}
        {...props}
      >
        <span className="hidden lg:inline-flex">Search...</span>
        <span className="inline-flex lg:hidden">Search...</span>
        <kbd className="pointer-events-none absolute right-[0.3rem] top-[0.3rem] hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
          <span className="text-xs">⌘</span>K
        </kbd>
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <div className="flex items-center border-b px-3">
          <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
          <input
            className={cn(
              "flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
            )}
            placeholder="Search..."
            aria-autocomplete="list"
            role="combobox"
            aria-expanded="true"
            type="text"
            onChange={(event) => setSearchValue(event.target.value)}
          ></input>
        </div>
        <CommandList>
          <SearchList modal={setOpen} searchValue={searchValue} />
        </CommandList>
      </CommandDialog>
    </>
  );
}
