export function Legend({ data, activeIndex }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {data.map((entry, index) => (
        <div
          key={`item-${index}`}
          className={`flex items-center space-x-2 p-2 rounded ${
            index === activeIndex ? "bg-gray-100" : ""
          }`}
        >
          <span
            className="w-4 h-4 inline-block"
            style={{ backgroundColor: entry.fill }}
          ></span>
          <span className="text-sm font-medium">
            {entry.feed_name} ({entry.total_min} minutes, {entry.percent}%)
          </span>
        </div>
      ))}
    </div>
  );
}
