import {
  CardTitle,
  CardDescription,
  CardHeader,
  CardContent,
  Card,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

export function LoginOverlay() {
  const closeButtonStyles = {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
  };

  let navigate = useNavigate();

  return (
    <Card
      className="mx-auto max-w-sm mg-top-space w-auto"
      style={{ width: "100%" }}
    >
      {/* <SquareX style={closeButtonStyles} className="space-y-2" /> */}
      <ArrowLeft
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer", margin: "5px" }}
      />
      <CardHeader className="space-y-1">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="http://dev.scrollne.ws/logo_nemesis.png"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              placeholder="m@example.com"
              required
              type="email"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="password">Password</Label>
            <Input
              placeholder="********"
              id="password"
              required
              type="password"
            />
          </div>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-info px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
            </button>
          </div>
        </div>
        <div class="mt-4 text-sm text-gray-600 text-center">
          <p>
            Not a member?{" "}
            <a href="/signup/" class="text-indigo-500 hover:underline">
              <b>Start a 14 day free trial</b>
            </a>
          </p>
        </div>
      </CardContent>
    </Card>
  );
}
