import { Footer } from "@/routes/footer";
import { SiteHeader } from "@/routes/site-header";
import { ShieldQuestion } from "lucide-react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useEffect, useState } from "react";
import { API_URL } from "@/config";
import axios from "axios";
import { LoadingWindow } from "./loading";

export function AccordionDemo() {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const url = `${API_URL}/api/v1/news/faqs/`;
      const response = await axios.get(url);
      setFaqs(response.data);
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return <LoadingWindow />;
  }

  return (
    <div className="py-2">
      <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
        Frequently asked questions
      </h2>
      <Accordion type="single" collapsible className="w-full">
        {faqs &&
          faqs.map(( item, index) => (
            <AccordionItem key={item.alias} value="item-1">
              <AccordionTrigger>{item.question}</AccordionTrigger>
              <AccordionContent>{item.answer}</AccordionContent>
            </AccordionItem>
          ))}
      </Accordion>
    </div>
  );
}

export function FAQPage() {
  return (
    <>
      <SiteHeader></SiteHeader>
      <div id="page-wrapper" className="full-page feed-view py-6">
        <div className="outer-wrapper clearfix" id="outer-wrapper">
          <div className="xl:container mx-auto px-3 sm:px-4 xl:px-2">
            <div className="row justify-content-center">
              <div className="fbt-main-wrapper col-xl-12">
                <div id="main-wrapper">
                  <div className="main-section" id="main_content">
                    <AccordionDemo />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
